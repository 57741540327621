
























































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import StandardInput from "@/components/design-system/inputs/StandardInput.vue";
import SelectInput from "@/components/design-system/inputs/SelectInput.vue";
import InputLabel from "@/components/design-system/inputs/InputLabelWrapper.vue";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import Draggable from "vuedraggable";
import { stringIsEmpty } from "@/helpers/string-formatting";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import { cloneDeep } from "lodash";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";

@Component({
  components: {
    "primary-btn": PrimaryButton,
    "standard-input": StandardInput,
    "select-input": SelectInput,
    "icon-tootlip": IconTooltip,
    "simple-confirmation-modal": SimpleConfirmationModal,
    draggable: Draggable,
    "icon-button": IconButton,
    "input-label": InputLabel
  }
})
export default class LoadBalanced extends Vue {
  @Prop() headers!: {}[];
  @Prop() numberOfGroupsData!: {}[];
  @Prop() items!: {
    id: number;
    numberOfGroups: number;
    primaryName: { name: string; href: string };
    groupVariation: string;
    namingTheme: string;
    priority: number;
    users: {}[];
  }[];
  private lBData = cloneDeep(this.items);
  private loadBalance = true;
  //Create-Update modal
  private displayModal = false;
  private numberOfGroups = 0;
  private primaryName = "";
  private groupVariation = "";
  private modalTitle = "";
  private modalText = "";
  private confirmButtonText = "";
  private modalKey = randomNumberGenerator(); /*needed for force reload component*/
  private modalAction = "";
  //confirm modal
  private displayConfirmModal = false;
  //end confirm modal
  //delete modal
  private displayDeleteModal = false;
  private groupId = NaN;
  private groupIdToDelete = NaN;
  //end delete modal
  private baseUrl = "./";

  //Modal For create and update
  private handleModal(groupId: any) {
    if (Number.isInteger(groupId)) {
      this.loadItemFields(groupId);
      this.modalTitle = "Edit Load Balanced Group";
      this.modalText =
        "Users assigned to a group can access its documents. The auto load balancing feature will ensure documents are distributed across your team evenly";
      this.confirmButtonText = "Save Changes";
      this.modalKey = randomNumberGenerator();
      this.modalAction = "update";
    } else {
      this.resetFields();
      this.modalTitle = "Create a New Load Balanced Group";
      this.modalText =
        "Users assigned to a group can access its documents. The auto load balancing feature will ensure documents are distributed across your team evenly";
      this.confirmButtonText = "Create Group";
      this.modalKey = randomNumberGenerator();
      this.modalAction = "create";
    }
    this.isModalDisplayed = !this.isModalDisplayed;
  }
  //Modal For balance mode switch
  private handleConfirmModal() {
    this.loadBalance = true;
    this.isConfirmModalDisplayed = !this.isConfirmModalDisplayed;
  }
  //Modal For delete specific group
  private handleDeleteModal(groupId: any) {
    if (Number.isInteger(groupId)) {
      this.groupIdToDelete = groupId;
    }
    this.isDeleteModalDisplayed = !this.isDeleteModalDisplayed;
  }

  private endDrag(value: any[]) {
    for (let i = 0; i < value.length; i++) {
      value[i].priority = i + 1;
    }
    this.lBData = value;
    console.log(this.lBData);
    console.log("API call update priority");
  }

  private loadItemFields(id: number) {
    const item = this.lBData.filter(el => el.id == id);
    this.groupId = id;
    this.numberOfGroups = item[0].numberOfGroups;
    this.primaryName = item[0].primaryName.name;
    this.groupVariation = item[0].groupVariation;
  }

  private resetFields() {
    this.numberOfGroups = 0;
    this.primaryName = "";
    this.groupVariation = "";
  }

  //events listener
  private listenNumberOfGroupsSelect(value: any) {
    this.numberOfGroups = value;
  }
  private listenGroupNameInput(value: string) {
    if (stringIsEmpty(value)) {
      this.primaryName = "";
    } else {
      this.primaryName = value;
    }
  }

  private createGroup() {
    if (this.numberOfGroups && this.primaryName && this.groupVariation) {
      const dataForSend = {
        numberOfGroups: this.numberOfGroups,
        primaryName: this.primaryName,
        groupVariation: this.groupVariation
      };
      if (this.modalAction == "create") {
        console.log("API call POST new group: ", dataForSend);
        //TODO remove when API call is added
        this.lBData.push({
          id: 50,
          primaryName: { name: this.primaryName, href: "" },
          //data used for data table
          numberOfGroups: this.numberOfGroups,
          groupVariation: this.groupVariation,
          namingTheme: `"Primary name + ${this.groupVariation}`,
          priority: 5,
          users: []
        });
        //END TODO
      } else {
        console.log("API call UPDATE group: ", dataForSend);
        //TODO remove when API call is added
        const el = this.lBData.filter(el => el.id == this.groupId);
        el[0].numberOfGroups = this.numberOfGroups;
        el[0]["primaryName"] = { name: this.primaryName, href: "" };
        el[0]["groupVariation"] = this.groupVariation;
        console.log(el[0]);
        //END TODO
      }
      this.resetFields();
      this.isModalDisplayed = false;
    } else {
      console.error("Create group fields are mandatory");
    }
  }

  private deleteGroup() {
    console.log(
      "API delete group with ID and wait for updated data: ",
      this.groupIdToDelete
    );
    //TODO remove when API call is added
    this.lBData = this.lBData.filter(el => el.id != this.groupIdToDelete);
    //end TOD
    this.isDeleteModalDisplayed = !this.isDeleteModalDisplayed;
    this.groupIdToDelete = NaN;
  }

  private switchMode() {
    console.log("API call: DELETE all Load Balanced groups");
    this.$emit("lookup", "disable");
  }

  private setGroupVariation(value: string) {
    this.groupVariation = value;
  }

  private nextCharacterAt(charcater: string, index: any) {
    return String.fromCharCode(charcater.charCodeAt(0) + index);
  }

  //computed
  private get isModalDisplayed() {
    return this.displayModal;
  }

  private set isModalDisplayed(value: boolean) {
    this.displayModal = value;
  }

  private get isConfirmModalDisplayed() {
    return this.displayConfirmModal;
  }

  private set isConfirmModalDisplayed(value: boolean) {
    this.displayConfirmModal = value;
  }

  private get isDeleteModalDisplayed() {
    return this.displayDeleteModal;
  }

  private set isDeleteModalDisplayed(value: boolean) {
    this.displayDeleteModal = value;
  }

  @Watch("loadBalance")
  showConfirmationModal() {
    if (!this.loadBalance) {
      this.isConfirmModalDisplayed = true;
    }
  }
}
