export class Condition {
  field: string;
  operator: string;
  value: string;
  condition: string;

  constructor(field: string, operator: string, value: string, condition = "") {
    this.condition = condition;
    this.field = field;
    this.operator = operator;
    this.value = value;
  }
}
