

























import FirstEntry from "@/components/document-groups/FirstEntry.vue";
import LoadBalanced from "@/components/document-groups/LoadBalanced.vue";
import NotLoadBalanced from "@/components/document-groups/NotLoadBalanced.vue";
import {
  DocumentGroupsFormItem,
  NotLoadBalancedData
} from "@/interfaces/document-groups-interfaces";
import { documentGroupsService } from "@/services/document-groups.service";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    "not-load-balanced": NotLoadBalanced,
    "load-balanced": LoadBalanced,
    "first-entry": FirstEntry
  }
})
export default class DocumentGroups extends Vue {
  private loadBalance = "disable";
  private isFetching = true;
  private notLoadBalancedData: NotLoadBalancedData = {
    fieldItems: [],
    operatorItems: [],
    conditionItems: [],
    tableHeaders: [],
    tableItems: []
  };
  // private loadBalancedData = cloneDeep(loadBalancedMockData);
  private blankDocumentGroupFormItem: DocumentGroupsFormItem = {
    key: "is blank",
    value: "Is Blank"
  };

  private get isLoadBalanceEnable(): string {
    return this.loadBalance;
  }

  private set isLoadBalanceEnable(value: string) {
    this.loadBalance = value;
  }

  private updateLoadBalance(value) {
    this.loadBalance = value;
  }

  async created() {
    this.notLoadBalancedData = await documentGroupsService.getDocumentGroupsAsync();
    this.addBlankOperatorItem();
    this.isFetching = false;
  }

  private addBlankOperatorItem() {
    this.notLoadBalancedData.operatorItems.push(
      this.blankDocumentGroupFormItem
    );
  }
}
