<template>
  <v-container class="wrapper pa-0">
    <v-card class="content-wrapper">
      <div class="body-wrapper top-round py-15">
        <div class="d-flex justify-center mt-15 mb-4">
          <h4>Set Document Distribution to Create Your First Document Group</h4>
        </div>
        <div class="d-flex justify-center my-4 mb-12">
          <div class="d-flex justify-center flex-column">
            <h5>
              You can manually group documents to be distributed to your
              processing team or use
            </h5>
            <h5>
              the Auto Load Balancing rule to ensure documents are evenly
              distributed to your team.
            </h5>
          </div>
        </div>
        <div class="d-flex justify-center mb-15">
          <secondary-button
            buttonText="Enable Auto Load Balancing"
            :handleClick="() => $emit('lookup', 'enable')"
            class="mr-1 py-3"
          />
          <primary-button
            buttonText="Set Groups Manually"
            :handleClick="() => $emit('lookup', 'disable')"
            class="ml-1 py-3"
          />
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script land="ts">
import { Vue, Component } from "vue-property-decorator";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
@Component({
  components: {
    "secondary-button": SecondaryButton,
    "primary-button": PrimaryButton
  }
})
export default class FirstEntry extends Vue {}
</script>

<style lang="scss" scoped>
.wrapper {
  font-family: "Karla", sans-serif !important;
}

::v-deep .config-title {
  padding: 32px 0 0 32px;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
}

.content-wrapper {
  padding: 32px;
}

::v-deep .v-icon {
  color: var(--theme-primary) !important;
}

.content-wrapper,
// prettier-ignore
::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
::v-deep .v-list {
  background-color: var(--theme-background) !important;
  box-shadow: none !important;
  color: var(--theme-primary) !important;
}

::v-deep .content-wrapper .v-sheet .v-card,
::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

::v-deep .v-list-item-group .v-list-item--active {
  background-color: var(--theme-surface) !important;
  border-left: 3px solid var(--theme-green-strong-apple);
  padding-left: 13px;
}

::v-deep .theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}

::v-deep .list {
  padding: 0 !important;
}

.body-wrapper {
  background-color: var(--theme-surface) !important;
  padding: 32px;
  position: relative;
  flex: 1;
}

.top-square {
  border-radius: 0 20px 20px 20px !important;
}

.top-round {
  border-radius: 20px !important;
}

::v-deep .v-list-item__icon {
  margin-right: 16px !important;
}

::v-deep .container {
  padding: 0 !important;
}
h4 {
  font-family: "Karla", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}
h5 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
</style>
