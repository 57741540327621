































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import StandardInput from "@/components/design-system/inputs/StandardInput.vue";
import SelectInput from "@/components/design-system/inputs/SelectInput.vue";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import Draggable, { DraggedContext } from "vuedraggable";
import { stringIsEmpty } from "@/helpers/string-formatting";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import { cloneDeep } from "lodash";
import { documentGroupsService } from "@/services/document-groups.service";
import { Condition } from "@/models/document-groups/condition-model";
import {
  Members,
  DocumentGroupsFormItem,
  NotLoadBalancedData,
  TableItems
} from "@/interfaces/document-groups-interfaces";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";
import options from "@/shared/constants/toast-options";
import { reportErrorCaptured } from "@/helpers/error-captured";
import Catch from "@/shared/decorators/catch-errors";
import { DOCUMENT_GROUP_ACTIONS } from "@/shared/constants/document-group";

@Component({
  components: {
    "primary-btn": PrimaryButton,
    "standard-input": StandardInput,
    "select-input": SelectInput,
    "icon-tooltip": IconTooltip,
    "simple-confirmation-modal": SimpleConfirmationModal,
    draggable: Draggable,
    "icon-button": IconButton
  }
})
export default class NotLoadBalanced extends Vue {
  // props
  @Prop() data!: NotLoadBalancedData;

  // reactive class properties
  private fieldItems = this.data.fieldItems.sort((a, b) =>
    a.value.toLowerCase() > b.value.toLowerCase()
      ? 1
      : b.value.toLowerCase() > a.value.toLowerCase()
      ? -1
      : 0
  );

  private operatorItems = this.data.operatorItems;
  private conditionItems = this.data.conditionItems;
  private headers = this.data.tableHeaders;
  private clonedTableItems = cloneDeep(this.data.tableItems);
  private loadBalance = false;

  // create/update group modal
  private displayModal = false;
  private defaultCondition: Condition = new Condition("", "", "");
  private additionalConditions: Condition[] = [];
  private members: Members[] = [];
  private id = 0;
  private groupId = 0;
  private priority = 0;
  private groupName = "";
  private modalTitle = "";
  private modalSubtitle = "";
  private confirmButtonText = "";
  private modalKey = randomNumberGenerator(); /*needed for force reload component*/
  private rerenderConditions = randomNumberGenerator();
  private modalAction = "";
  // confirm modal
  private displayConfirmModal = false;
  // delete modal
  private displayDeleteModal = false;
  private groupIdToDelete = NaN;
  private groupToReasignDeletedGroupInvoices = NaN;
  private deleteModalKey = randomNumberGenerator();

  // determine text to display as tooltip of drag/drop icon
  getText(item: TableItems) {
    return item.name != "Unknown"
      ? "Drag & drop to change priority"
      : "This catchall document group should remain last priority";
  }

  isDraggable(context: DraggedContext<any>) {
    const { index, futureIndex } = context;
    return (
      this.clonedTableItems[index].name !== "Unknown" &&
      this.clonedTableItems[futureIndex].name !== "Unknown"
    );
  }

  checkIfMoveable(event: any) {
    return this.isDraggable(event.draggedContext);
  }

  //Modal For create and update
  private handleCreateModal() {
    this.resetModalInputs();
    this.modalTitle = "Create a New Document Group";
    this.modalSubtitle =
      "Users assigned to this group can access documents that meet the selected criteria";
    this.confirmButtonText = "Create Group";
    this.modalKey = randomNumberGenerator();
    this.modalAction = DOCUMENT_GROUP_ACTIONS.CREATE;
    this.isModalDisplayed = !this.isModalDisplayed;
  }

  private handleUpdateModal(id: number) {
    this.loadGroupForUpdate(id);
    this.modalTitle = "Edit Document Group";
    this.modalSubtitle =
      "Users assigned to this group can access documents that meet the selected criteria";
    this.confirmButtonText = "Save Changes";
    this.modalKey = randomNumberGenerator();
    this.modalAction = DOCUMENT_GROUP_ACTIONS.UPDATE;
    this.isModalDisplayed = !this.isModalDisplayed;
  }
  //Modal For balance mode switch
  private handleConfirmModal() {
    this.loadBalance = false;
    this.isConfirmModalDisplayed = !this.isConfirmModalDisplayed;
  }
  //Modal For delete specific group
  private handleDeleteModal(id: any) {
    if (Number.isInteger(id)) {
      this.groupIdToDelete = id;
    }
    this.deleteModalKey = randomNumberGenerator();
    this.isDeleteModalDisplayed = !this.isDeleteModalDisplayed;
  }

  private switchMode() {
    // TO-DO when implementing load balanced group, make API call
    // to delete all non-load balanced groups
    this.$emit("lookup", "enable");
  }

  private get isAllowedGroupName() {
    const trimedName = this.groupName.trim();
    if (this.modalAction == DOCUMENT_GROUP_ACTIONS.UPDATE) {
      return !(trimedName != "");
    }
    return (
      this.availableGroups.filter(group => group.value.trim() == trimedName)
        .length > 0 || trimedName == ""
    );
  }

  @Catch(reportErrorCaptured)
  private async changePriority(items: any[]) {
    items.forEach((item: TableItems, index: number) => {
      item.priority = index + 1;
    });
    this.clonedTableItems = items;
    await Promise.all(
      this.clonedTableItems.map(el =>
        documentGroupsService.updateDocumentGroupPriorityAsync(
          el.id,
          el.priority
        )
      )
    );
  }

  @Catch(reportErrorCaptured)
  private async createGroup() {
    // update priority of unknown group and position new group right above unknown
    this.priority =
      (this.clonedTableItems.findIndex(
        (item: TableItems) => item.name == "Unknown"
      ) ?? 0) + 1;
    const unknownGroup = this.clonedTableItems.find(
      (item: TableItems) => item.name == "Unknown"
    );
    if (unknownGroup) unknownGroup.priority += 1;

    const resp = await documentGroupsService.createDocumentGroupAsync({
      id: this.id,
      groupID: this.groupId,
      priority: this.priority,
      name: this.groupName,
      conditions: cloneDeep([
        this.defaultCondition,
        ...this.additionalConditions
      ]),
      members: []
    });
    this.clonedTableItems.splice(this.priority - 1, 0, {
      ...resp,
      priority: this.priority
    });
    await this.changePriority(this.clonedTableItems);
    this.isModalDisplayed = false;
  }

  @Catch(reportErrorCaptured)
  private async updateGroup() {
    const resp = await documentGroupsService.updateDocumentGroupAsync({
      id: this.id,
      groupID: this.groupId,
      priority: this.priority,
      name: this.groupName,
      conditions: cloneDeep([
        this.defaultCondition,
        ...this.additionalConditions
      ]),
      members: this.members
    });
    this.clonedTableItems = this.clonedTableItems.filter(
      el => el.id != this.id
    );
    this.clonedTableItems.push(resp);
    this.clonedTableItems = this.clonedTableItems.sort(
      (n1, n2) => n1.priority - n2.priority
    );
    this.isModalDisplayed = false;
  }

  private performAction() {
    if (this.modalAction == DOCUMENT_GROUP_ACTIONS.CREATE) this.createGroup();
    if (this.modalAction == DOCUMENT_GROUP_ACTIONS.UPDATE) this.updateGroup();
  }

  @Catch(reportErrorCaptured)
  private async deleteGroup() {
    if (!this.groupToReasignDeletedGroupInvoices) {
      this.$toasted.show(
        "<p>Please select a group to reassign documents to</p>",
        options.INFO_OPTIONS
      );
      return;
    }
    await documentGroupsService.deleteDocumentGroupAsync(
      this.groupIdToDelete,
      this.groupToReasignDeletedGroupInvoices
    );
    this.clonedTableItems = this.clonedTableItems.filter(
      el => el.id != this.groupIdToDelete
    );
    this.isDeleteModalDisplayed = !this.isDeleteModalDisplayed;
    this.groupIdToDelete = NaN;
    this.groupToReasignDeletedGroupInvoices = NaN;
    this.deleteModalKey = randomNumberGenerator();
  }

  private loadGroupForUpdate(id: number) {
    const item = cloneDeep(this.clonedTableItems.filter(el => el.id == id));
    this.id = id;
    this.groupId = item[0].groupID;
    this.groupName = item[0].name;
    this.priority = item[0].priority;
    this.defaultCondition = item[0].conditions.length
      ? item[0].conditions[0]
      : new Condition("", "", "");
    item[0].conditions.shift();
    this.additionalConditions = item[0].conditions.length
      ? item[0].conditions
      : [];
    this.members = item[0].members;
  }

  private resetModalInputs() {
    this.groupName = "";
    this.defaultCondition = new Condition("", "", "");
    this.additionalConditions = [];
  }

  private get areDefaultConditionsEmpty() {
    let result;
    if (this.defaultCondition.operator == "Is Blank") {
      result =
        this.defaultCondition.field == "" ||
        this.defaultCondition.value.trim() != "";
      return result;
    }
    result =
      this.defaultCondition.field == "" ||
      this.defaultCondition.operator == "" ||
      this.defaultCondition.value.trim() == "";
    return result;
  }

  private get areAdditionalConditionsEmpty() {
    let result;
    for (let i = 0; i < this.additionalConditions.length; i++) {
      if (this.defaultCondition.operator == "Is Blank") {
        result =
          this.defaultCondition.field == "" ||
          this.defaultCondition.value.trim() != "";
        return result;
      }
      if (
        this.additionalConditions[i].field == "" ||
        this.additionalConditions[i].operator == "" ||
        this.additionalConditions[i].value.trim() == ""
      )
        result = true;
      return result;
    }
    result = false;
    return result;
  }

  private addCondition() {
    this.additionalConditions = [
      ...this.additionalConditions,
      new Condition("", "", "", "")
    ];
  }

  private removeCondition(conditionIndex: number) {
    this.additionalConditions.splice(conditionIndex - 1, 1);
    this.rerenderConditions = randomNumberGenerator();
  }

  private getConditionValueByID(
    conditionObject: DocumentGroupsFormItem[],
    key: string
  ): string {
    return conditionObject.filter(el => el.key == key)[0].value;
  }
  private getConditionIDByValue(
    conditionObject: DocumentGroupsFormItem[],
    value: string
  ): string {
    return conditionObject.filter(el => el.value == value)[0].key;
  }
  //events listener
  private listenGroupNameInput(groupName: string) {
    if (stringIsEmpty(groupName)) {
      this.groupName = "";
    } else {
      this.groupName = groupName;
    }
  }

  private listenConditionConditionSelect(conditionKey: string, index: number) {
    this.additionalConditions[index - 1].condition = this.getConditionValueByID(
      this.conditionItems,
      conditionKey
    );
  }

  private listenConditionFieldSelect(fieldKey: string, index = 0) {
    const fieldName = this.getConditionValueByID(this.fieldItems, fieldKey);
    if (index) this.additionalConditions[index - 1].field = fieldName;
    else this.defaultCondition.field = fieldName;
  }

  private listenConditionOperatorSelect(operatorKey: string, index = 0) {
    const operatorName = this.getConditionValueByID(
      this.operatorItems,
      operatorKey
    );
    if (index) this.additionalConditions[index - 1].operator = operatorName;
    else this.defaultCondition.operator = operatorName;
  }

  private listenConditionValueInput(value: string, index = 0) {
    if (index) this.additionalConditions[index - 1].value = value;
    else this.defaultCondition.value = value;
  }

  private listenNewSelectedGroupId(value: number) {
    this.groupToReasignDeletedGroupInvoices = value;
  }

  //computed
  private get isModalDisplayed() {
    return this.displayModal;
  }

  private set isModalDisplayed(value: boolean) {
    this.displayModal = value;
  }

  private get isConfirmModalDisplayed() {
    return this.displayConfirmModal;
  }

  private set isConfirmModalDisplayed(value: boolean) {
    this.displayConfirmModal = value;
  }

  private get isDeleteModalDisplayed() {
    return this.displayDeleteModal;
  }

  private set isDeleteModalDisplayed(value: boolean) {
    this.displayDeleteModal = value;
  }

  private get availableGroups() {
    return this.clonedTableItems.map(el => {
      return { value: el.name, key: el.id };
    });
  }

  private get cleanAvailableGroups() {
    const cleanedAvailableGroups = this.availableGroups.filter(
      el => el.key != this.groupIdToDelete
    );
    return cleanedAvailableGroups;
  }

  private get groupNameToDelete() {
    return this.groupIdToDelete
      ? this.availableGroups.filter(el => el.key == this.groupIdToDelete)[0]
          .value
      : "";
  }

  @Watch("loadBalance")
  notifyParent() {
    if (this.loadBalance) {
      this.isConfirmModalDisplayed = true;
    }
  }
}
