import { app } from "@/main";
import { TableItems } from "@/interfaces/document-groups-interfaces";

class DocumentGroupsService {
  public async getDocumentGroupsAsync() {
    const response = await app.$ravenapi.get("/api/documentgroups/get");
    return response.data;
  }

  public async createDocumentGroupAsync(documentGroup: TableItems) {
    const response = await app.$ravenapi.post(
      `/api/documentgroups/create`,
      documentGroup
    );
    return response.data;
  }

  public async updateDocumentGroupAsync(documentGroup: TableItems) {
    const response = await app.$ravenapi.put(
      `/api/documentgroups/update`,
      documentGroup
    );
    return response.data;
  }

  public async updateDocumentGroupPriorityAsync(
    groupID: number,
    priorityOrder: number
  ) {
    const response = await app.$ravenapi.put(
      `/api/documentgroups/updatepriority/${groupID}?seqID=${priorityOrder}`,
      null
    );
    return response.data;
  }

  public async deleteDocumentGroupAsync(
    groupID: number,
    reasingToGroupID: number
  ) {
    return await app.$ravenapi.delete(
      `/api/documentgroups/delete/${groupID}?newRefID=${reasingToGroupID}`
    );
  }
}

export const documentGroupsService = new DocumentGroupsService(); // singleton
